var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('OverviewWrapper',{attrs:{"value":_vm.selectedItem,"sidebar-items":[
    {
      title: 'Übersicht',
      icon: 'mdi-info',
      name: 'info',
      active: function (value) {
        return value && value.integration;
      },
    } ]},on:{"input":_vm.onInput},scopedSlots:_vm._u([{key:"sidebar.info",fn:function(ref){
  var item = ref.item;
return [_c('TicketSidebarInfo',{attrs:{"item":item}})]}}],null,true)},[_c('v-row',[_c('v-col',[_c('AppCard',{attrs:{"title":_vm.$t('All Tickets'),"title-actions":""},scopedSlots:_vm._u([{key:"titleActions",fn:function(){return [_c('div',{staticClass:"d-flex flex-column flex-md-row align-end align-md-center justify-end"},[_c('v-text-field',{staticClass:"mb-4 mb-md-0 mr-md-4",staticStyle:{"max-width":"200px"},attrs:{"outlined":"","hide-details":"auto","dense":"","type":"text","label":"Search Ticket","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"to":"Tickets/create","color":"secondary","rounded":"","dark":""}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Ticket erstellen")])],1)],1)]},proxy:true}])},[(_vm.pagination)?_c('AppPagination',{attrs:{"pagination":_vm.pagination,"location":"top"}}):_vm._e(),_c('v-data-table',{staticClass:"v-data-table__overview",attrs:{"headers":_vm.headers,"items":_vm.tickets,"page":_vm.pagination.page,"items-per-page":_vm.pagination.perPage,"loading":_vm.pending.getTickets,"hide-default-footer":"","item-class":function (item) { return 'clickable' + (['closed', 'storno', 'canceled'].includes(item.status) ? _vm.highlightClasses : ''); }},on:{"click:row":_vm.onClickRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-switch',{attrs:{"label":"Show closed tickets"},model:{value:(_vm.showClosedTickets),callback:function ($$v) {_vm.showClosedTickets=$$v},expression:"showClosedTickets"}})],1)]},proxy:true},{key:"item.client",fn:function(ref){
  var item = ref.item;
return [(item.handle)?[_c('b',[_vm._v(_vm._s(item.handle.organisation))]),_vm._v(" "+_vm._s(item.handle.firstname)+" "+_vm._s(item.handle.lastname)+" ")]:_vm._e()]}},{key:"item.priority",fn:function(ref){
  var item = ref.item;
return [_c('v-chip',{staticClass:"mr-1",attrs:{"x-small":"","color":"primary"}},[_vm._v(_vm._s(item.priority))])]}},{key:"item.duedate",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.duedate,'DD.MM.YYYY'))+" ")]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('TicketRowActions',{attrs:{"ticket":item,"pending-tickets":_vm.pendingTickets}})]}}],null,true)}),(_vm.pagination)?_c('AppPagination',{attrs:{"pagination":_vm.pagination}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }